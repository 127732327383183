import React, { useEffect } from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';

const riveFile = new URL('../rive/play-pause-button.riv', import.meta.url);

const RivePlayPauseButton = ({ isPlaying, handlePlayPause }) => {
  const { rive, RiveComponent } = useRive({
    src: riveFile.href,
    stateMachines: 'State Machine 1',
    autoplay: true,
  });

  const pausedInput = useStateMachineInput(rive, 'State Machine 1', 'Paused');

  useEffect(() => {
    if (pausedInput) {
      pausedInput.value = !isPlaying;
    }
  }, [isPlaying, pausedInput]);

  const handleClick = () => {
    handlePlayPause();
  };

  return (
    <div className="rive-play-pause-btn" onClick={handleClick}>
      <RiveComponent />
    </div>
  );
};

export default RivePlayPauseButton;
