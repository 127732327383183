import React, { Component } from "react"
import Header from "./header.jsx"
import TypingCodeEffect from "./components/TypingCodeEffect.jsx"

const compareApp = new URL("./img/code/compare-app.png", import.meta.url)
const stewardAppStats = new URL("./img/code/steward-app-stats.png", import.meta.url)
const stewardAppGear = new URL("./img/code/steward-app-gear.png", import.meta.url) 
const autoExtension = new URL("./img/code/auto-extension.png", import.meta.url)


class Code extends Component {
    render() {
        const codePage = (
            <div>
                <Header />
                <div className="page-body product code">
                    <div className="title-text">
                    <TypingCodeEffect />
                    </div>
                    <div className="description-text">
                        <p>My love for coding came from my excitement for learning and being able to build the exact tools I need for a task. For clients, I create tailored coding solutions that fit their needs. Whether it’s building custom tools to streamline business operations, developing reusable templates for motion design, or writing code across a wide range of platforms, I’ve got you covered. With expertise in a variety of programming languages spanning web, mobile, and desktop, I want to help solve your unique problem. Let’s build something together.</p>
                    </div>
                    <div className="centered-section-text">Mac and iOS Apps</div>
                    <div className="centered-banner-text">Compare App</div>
                    <div className="description-text">
                        <p> </p>
                        <img className="compare-app" src={compareApp} alt="Compare App"/>
                        <p>Compare was designed to verify that all of the files in one folder match the files in another folder. I do this by creating SHA-256 hashes of the files and then comparing them. I enhanced the app by generating MHL files and the ability to compare the MHL files at a later date, either to the source files to see if they have changed or another MHL file. I use this app in my workflow weekly as I copy files between working SSD drives, footage from SD cards, and data management for clients.</p>
                        <p>Would this app help your business? Reach out using the <a href="/contact">contact form</a> and let me know. Perhaps I can bring it to market someday.</p>
                    </div>

                    <div className="centered-banner-text">Steward App</div>
                    <div className="description-text">
                        <p>Steward is a Mac and iOS app that allows me to generate invoices, estimate video gear rentals, track my time, generate reports, and visualize my business expenses using data from YNAB.</p>
                        <p>I wanted an app that could help with very specific aspects of my business workflow. I wanted to generate invoices easily, but most services pushed me to request payment through their website. I wanted a simple PDF generator that would also name the file correctly. I wanted a way to estimate video gear rentals easily based on my inventory. I wanted an easy way to convert time tracking data into an invoice. I wanted a way to visualize different parts of my business expenses using data from YNAB (my preferred budgeting software).</p>
                        <p>Steward is my solution for all these problems.</p>
                        <img className="steward-app" src={stewardAppStats} alt="Steward App Stats"/>
                        <p>It is a work in progress. I'm always adding new features and fixing bugs. But it has allowed me to do exactly what I need for my business.</p>
                        <img className="steward-app" src={stewardAppGear} alt="Steward App Gear"/>
                    </div>

                    <div className="centered-section-text">After Effects and Premiere Pro Extensions</div>
                    <div className="centered-banner-text">Auto Extension</div>
                    <div className="description-text">
                        <p>Auto Extension is a custom After Effects extension that I made to speed up things I do every day in After Effects.</p>
                        {/* List of features */}
                        <ul>
                            <li>Center selected layers to the 3D Camera</li>
                            <li>Reverse Layer Order</li>
                            <li>Create Matte Shapes with customizable margins</li>
                            <li>Explode shape groups into their own layers</li>
                            <li>Version and replace the selected pre-comp</li>
                            <li>Create a cursor shape layer for tutorials</li>
                            <li>Set layers recursively as 3D enabled</li>
                            <li>Bounce the scale of selected layers</li>
                            <li>Rig anchor points using expressions</li>
                            <li>Search for text inside expressions</li>
                            <li>Etc.</li>
                        </ul>
                        <img className="auto-extension" src={autoExtension} alt="Auto Extension"/>
                    </div>
                    <div className="centered-banner-text">Replace Clips Extension</div>
                    <div className="description-text">
                        <p>Replace Clips is a Premiere Pro Extension that does one thing: it replaces a selected clip or sequence from the selected timeline with another clip or sequence from your project.
                        </p>
                        <p>You can do this same operation using Shift + Alt + Drag. But you have to do this for each and every cut. If you try and do multiple at a time, it does not do the correct operation. It should take the matching in and out points from the previous clip and apply them to the new one. This works individually, but not on multiple clips.</p>
                        <p>Enter the Replace Clips extension.</p>
                        <p>Originally, I designed this to fill the gap between Descript XML exports and using Multi-Cam Sequences in Premiere. I would get a detailed paper cut from a client in Descript, but then getting that converted over to a Premiere project with the correct nested multi-cam sequence took forever for the hundreds of cuts that would be in a documentary short film.</p>
                        <p>This extension has saved me an enormous amount of time. If it would help your workflow, please <a href="/contact">reach out.</a></p>
                    </div>
                </div>
            </div>
        )
        return codePage
    }
}

export default Code