import React, { Component, useState } from "react"
import { BrowserRouter, Route, Routes, Switch, Link, Outlet } from "react-router-dom"
// const animationData = new URL("./lottie/menu-button.json", import.meta.url)
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import animationData from "./lottie/menu-button.json"
import TypingCodeEffect from "./components/TypingCodeEffect"

// import logoAnimationHome from "./lottie/Variable Studio - Home Icon - Lottie - 01.json"
const logo = new URL("./img/variable-studio-01.png", import.meta.url)
const navigationStyleWide = "navigation wide"
const navigationStyleThin = "navigation thin"
const navigationStyleThinHidden = "navigation thin offscreen"
var navigationStyle = navigationStyleWide

const e = React.createElement;

class Header extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef()
        this.logoAnimationPlayer = React.createRef()
        this.direction = 1
        // this.goHome = this.goHome.bind(this)
        this.state = {
            isNavExpanded: false
        }
    }

    // const [isNavExpanded, setIsNavExpanded] = useState(false)

    goHome = (e) => {
        // console.log("Going Home")
    }

    showWork = (e) => {
        // console.log("Show Work")
    }

    showFilm = (e) => {
        // console.log("Show Film")
    }

    showAnimation = (e) => {
        // console.log("Show Animation")
    }

    showMotionTemplates = (e) => {
        // console.log("Show Motion Templates")
    }

    showCode = (e) => {
        // console.log("Show Code")
    }

    showAbout = (e) => {
        // console.log("Show About")
    }

    showContact = (e) => {
        // console.log("Show Contact")
    }

    showMenu = (e) => {
        this.player.current.play()
        // console.log("Show Menu")
    }

    playLogoAnimation = (e) => {
        this.logoAnimationPlayer.current.play()
    }

    reverseAnimation = (e) => {
        if (this.direction == 1) {
            this.direction = -1
        } else {
            this.direction = 1
        }
        this.player.current.setPlayerDirection(this.direction)
        console.log("Reverse Animation")
    }

    toggleNavigation = (e) => {
        if (this.direction == 1) {
            this.setState({
                isNavExpanded: true
            })
        } else {
            this.setState({
                isNavExpanded: false
            })
        }
        console.log("Toggle Navigation")
    }

    render() {
        const myHeader = (
            <div className="header">
                <Link to="/">
                    <img className="logo" src={logo} alt="Logo" onClick={this.goHome}/>
                </Link>
                <div className={this.state.isNavExpanded ? navigationStyleThin : navigationStyleThinHidden}>
                    {/* <Link to="/work">
                        <h4 className="nav-button" onClick={this.showWork}>All Work</h4>
                    </Link> */}
                    {/* Horizontal Divider Line */}
                    {/* <div className="nav-divider"></div> */}
                    <Link to="/film">
                        <h4 className="nav-button" onClick={this.showFilm}>Film</h4>
                    </Link>
                    <Link to="/animation">
                        <h4 className="nav-button" onClick={this.showAnimation}>Animation</h4>
                    </Link>
                    <Link to="/code">
                        {/* <h4 className="nav-button" onClick={this.showCode}>Code</h4> */}
                        <TypingCodeEffect />
                    </Link>
                    <Link to="/motion-templates">
                        <h4 className="nav-button" onClick={this.showMotionTemplates}>Motion Templates</h4>
                    </Link>
                    <Link to="/logo-animations">
                        <h4 className="nav-button">Logo Animations</h4>
                    </Link>
                    <div className="nav-divider"></div>
                    <Link to="/about">
                        <h4 className="nav-button" onClick={this.showAbout}>About</h4>
                    </Link>
                    <Link to="/contact">
                        <h4 className="nav-button" onClick={this.showContact}>Contact</h4>
                    </Link>
                </div>
                <div className="menu-button" onClick={this.showMenu}>
                    <Player
                        onEvent={event => {
                            if (event == 'complete') this.reverseAnimation()
                            if (event == 'play') this.toggleNavigation()
                        }}
                        ref={this.player} // set the ref to your class instance
                        autoplay={false}
                        loop={false}
                        src={animationData}
                        keepLastFrame={true}
                        >
                        <Controls visible={false} />
                    </Player>
                </div>
            </div>
        );

        return myHeader;
    }
}

export default Header