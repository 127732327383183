import React, { Component } from "react"
import { BrowserRouter, Route, Routes, Switch, Link, Outlet, NavLink } from "react-router-dom"
import Header from "./header"

const profilePicture = new URL("./img/profile-picture/Aaron - Profile Picture - 2024 - Cropped.jpeg", import.meta.url)

class About extends Component {
    render() {
        const myElement = (
            <div className="about">
                <Header />
                <div className="about-body">
                    <h1>About</h1>

                    <img className="profile-pic" src={profilePicture} alt="Profile Picture" />

                    <p>
                        Variable Studio LLC is a small video and code production company in Austin, TX run by Aaron Campbell. Variable's services span filming, editing, animation, motion graphics, and code.
                    </p>
                    <p>
                        Specializing in <span className="tooltip">Motion Graphic Templates (or MoGRTs)<span className="tooltiptext">A customizable template file used in Adobe Premiere Pro and After Effects, designed to streamline the integration of motion graphics into video projects without requiring extensive graphic design expertise.</span></span> for Premiere Pro and After Effects, the team at Variable loves combining code with animation. Often times code is non-visual. But when combined with animation, code suddenly becomes responsive and there is a fast feedback loop. This has the added benefit of giving clients responsive templates allowing the same motion graphic to be quickly adjusted and used hundreds of times.
                    </p>


                    <p>
                        Variable Studio offers a wide variety of video services from editing tutorials and teaching content to filming music videos and documentaries.
                    </p>
                    <p>
                        Aaron enjoys mountain biking, hanging out with his family, and eating at his favorite ramen spot, Ramen Tatsuya.
                    </p>
                    <p>
                        Take a moment to check out previous <Link className="innerLink" to="/work">work</Link>, and when you're ready, <Link className="innerLink" to="/contact">reach out</Link> to get your next project started.
                    </p>
                </div>
            </div>
        )
        return myElement
    }
}

export default About